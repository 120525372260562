<template>
  <div class="ecosystem-edit">
    <v-container fluid fill-height>
      <c-ecosystem-nav :ecosystemId="ecosystemId"></c-ecosystem-nav>

      <v-container class="ecosystem-edit__content">
        <v-row justify="center">
          <v-col class="d-flex flex-column">
            <!-- Header -->
            <c-page-heading
              class="text-left"
              :heading="$t('ecosystems.edit.manage')"
              :subHeading="$t('ecosystems.edit.ecosystem')"
            ></c-page-heading>

            <span
              class="subheading"
              data-cy="hereYouCanManageYourEcosystemAndChangeItsMainSettings"
              >{{
                $t('ecosystems.edit.hereYouCanManageYourEcosystemAndChangeItsMainSettings')
              }}</span
            >

            <!-- Content -->
            <v-row>
              <v-col sm="12" md="10" lg="8">
                <c-ecosystem-form
                  v-if="loaded"
                  v-model="ecosystem"
                  :disableSubmit="disableSubmit"
                  :submitLabel="$t('ecosystems.edit.button')"
                  :cancelLabel="$t('common.cancel')"
                  @submit="onFormSubmit"
                  @file-selected="onFileUpload"
                  @file-delete="onFileDelete"
                  @cancel="onCancel"
                  ref="form"
                  showCancel
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-container>

    <m-modal
      v-model="showModal"
      :title="$t('ecosystems.edit.submitModalTitle')"
      :message="$t('ecosystems.edit.submitModalMessage')"
      width="610px"
      icon="/images/svgs/clipboard-illustration.svg"
      data-cy="confirmation-modal"
    >
      <template #buttons>
        <m-button
          class="modal__close"
          variant="secondary"
          @click="showModal = false"
          data-cy="cancel"
          >{{ $t('common.cancel') }}</m-button
        >

        <m-button class="modal__close" @click="onSubmit(pendingValue)" data-cy="confirm-save">{{
          $t('common.confirm')
        }}</m-button>
      </template>
    </m-modal>
  </div>
</template>

<script>
import { MModal, MButton } from 'gatherings-storybook'
import { cloneDeep, isEqual } from 'lodash'
export default {
  components: {
    MModal,
    MButton,
  },
  props: {
    ecosystemId: {
      type: String,
    },
  },
  data: () => {
    return {
      ecosystem: {
        id: null,
        name: '',
        timeZone: '',
        style: {
          primaryColour: null,
          secondaryColour: null,
          logos: [],
        },
      },
      disableSubmit: false,
      loaded: false,
      showModal: false,
      hasEcosystemBrandingChanged: false,
      previousStyleValue: null,
    }
  },
  watch: {
    ecosystemId: {
      handler(id) {
        this.getEcosystem(id)
      },
      immediate: true,
    },
    // Watch the branding object for changes to check if we need to notify the user
    'ecosystem.style': {
      handler(newVal) {
        // Prevent watcher from firing on initial data load
        if (!this.loaded) return

        this.hasEcosystemBrandingChanged = !isEqual(newVal, this.previousStyleValue)
        this.previousStyleValue = cloneDeep(newVal)
      },
      deep: true,
    },
  },
  methods: {
    onFormSubmit(ecosystem) {
      // Only show modal if branding has changed
      if (this.hasEcosystemBrandingChanged) {
        this.showConfirmModal(ecosystem)
      } else {
        // Otherwise just submit
        this.onSubmit(ecosystem)
      }
    },
    async onSubmit(ecosystem) {
      this.$store.dispatch('ui/toggleLoading', { show: true })

      // Prevent user spamming submit
      this.disableSubmit = true

      // Update the ecosystem style
      try {
        const response = await this.$store.dispatch('ecosystems/updateStyle', {
          ecosystemId: this.ecosystemId,
          data: ecosystem.style,
        })

        this.ecosystem.style = response
      } catch (e) {
        console.error(e)
      }

      // Update the ecosystem values
      try {
        await this.$store.dispatch('ecosystems/update', {
          ecosystemId: this.ecosystemId,
          data: ecosystem,
        })
      } catch (e) {
        console.error(e)
      }

      this.$store.dispatch('ui/toggleLoading', { show: false })

      // Reset
      this.disableSubmit = false
      this.showModal = false
      this.hasEcosystemBrandingChanged = false
      this.$refs.form.reset()

      this.notifyEcosystemSaved()
    },
    notifyEcosystemFailed() {
      this.$store.dispatch('ui/launchSnackbar', {
        color: 'error',
        message: this.$t('ecosystems.edit.ecosystemHasNotBeenUpdated'),
        subMessage: this.$t(
          'ecosystems.edit.thereHasBeenAnIssueProcessingYourRequestPleaseTryAgain'
        ),
        buttonColor: 'white',
        icon: 'mdi-alert-octagon',
      })
    },
    notifyFileUploadFailed() {
      this.$store.dispatch('ui/launchSnackbar', {
        color: 'error',
        message: this.$t('ecosystems.edit.fileUploadFailed'),
        subMessage: this.$t(
          'ecosystems.edit.thereHasBeenAnIssueProcessingYourRequestPleaseTryAgain'
        ),
        buttonColor: 'white',
        icon: 'mdi-alert-octagon',
      })
    },
    notifyEcosystemSaved() {
      this.$store.dispatch('ui/launchSnackbar', {
        color: 'green',
        message: this.$t('ecosystems.edit.ecosystemSavedToast'),
        subMessage: this.$t('ecosystems.edit.yourEcosystemHasbeenSuccessfullySaved'),
        buttonColor: 'white',
        icon: 'mdi-checkbox-marked-circle',
      })
    },
    async getEcosystem(ecosystemId) {
      this.loaded = false

      await this.$store.dispatch('ecosystems/get', ecosystemId)

      const data = this.$store.getters['ecosystems/getById'](ecosystemId)

      const ecosystem = {
        ...this.ecosystem,
        ...data,
      }

      ecosystem.style = await this.$store.dispatch('ecosystems/getStyle', {
        ecosystemId,
      })

      this.ecosystem = ecosystem

      // Setup initial value to track any changes to the ecosystem style object
      this.previousStyleValue = cloneDeep(this.ecosystem.style)

      this.$nextTick(() => {
        this.loaded = true
      })
    },
    onFileDelete(banner) {
      const index = this.ecosystem.style.logos.findIndex(
        logo => logo.textureId === banner.textureId
      )
      this.ecosystem.style.logos[index].textureId = null
    },
    async onFileUpload({ file, banner }) {
      // Already has a file associated with this field
      if (banner.textureId) {
        this.onFileDelete(banner)
      }

      this.disableSubmit = true

      const index = this.ecosystem.style.logos.findIndex(
        logo => logo.displayName === banner.displayName
      )

      try {
        this.$refs.form.setAssetPickerLoading(index, true)
        const files = await this.uploadFile(file)
        this.ecosystem.style.logos[index].textureId = files[0].id
        this.$refs.form.setAssetPickerLoading(index, false)
      } catch (e) {
        console.error(e)
        this.$refs.form.resetAssetPicker(index)
        this.notifyFileUploadFailed()
      }

      this.disableSubmit = false
    },
    async uploadFile(file) {
      const response = await this.$store.dispatch('files/upload', {
        ecosystemId: this.ecosystem.id,
        files: [file],
      })

      const files = response.data.data

      // Check if success
      if (!files[0].succeeded) {
        throw 'File upload failed'
      }

      return files
    },
    onCancel() {
      this.$router.push({ path: `/ws/${this.ecosystemId}` })
    },
    showConfirmModal(ecosystem) {
      this.pendingValue = ecosystem
      this.showModal = true
    },
  },
}
</script>

<style lang="scss">
.ecosystem-edit__content {
  font-family: Mulish !important;
  gap: 24px;

  // TODO: Update vuetify breakpoints variable to use these breakpoint settings
  @media (min-width: 960px) {
    max-width: 840px;
  }
  @media (min-width: 1264px) {
    max-width: 1120px;
  }
  @media (min-width: 1904px) {
    max-width: 1240px;
  }

  form.v-form {
    margin: -16px;
  }
}

.ecosystem-edit {
  h1 {
    margin-left: -0.3rem;
  }
}

.card {
  margin-bottom: 24px;
}

.subheading {
  margin-top: -48px;
  margin-bottom: 32px;
  font-size: 1.285rem;
  line-height: 1.5;
}

.v-application {
  .modal {
    font-family: 'Mulish' !important;
  }
}
</style>
